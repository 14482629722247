import React from "react";
// import { Navigate } from "react-router-dom";
import PrivateLayout from "./layouts/PrivateLayout/PrivateLayout";
import PublicLayout from "./layouts/PublicLayout/PublicLayout";
// import PageNotFoundPage from "./views/pages/PageNotFound/404PageNotFound";
import Home from "./views/pages/Home";
import { Config } from "./helpers/context/config";

// publicRoute

export const publicRoute = [
  {
    path: Config?.appBaseURL,
    element: <PublicLayout />,
    children: [{ path: Config?.appBaseURL, element: <Home /> }],

    // if You have Subdomain
    // { path: Config?.appBaseURL + "/notfound", element: <PageNotFoundPage /> },
    // {
    //   path: "*",
    //   element: <Navigate to={Config?.appBaseURL + "/notfound"} />,
    // },

    // if Not Have Sub Domain
    // { path: "*", element: <PageNotFoundPage /> },
  },
];

// privateRoute

export const privateRoute = [
  {
    path: Config?.appBaseURL,
    element: <PrivateLayout />,
    children: [{ path: Config?.appBaseURL, element: <Home /> }],

    // if You have Subdomain
    // { path: Config?.appBaseURL + "/notfound", element: <PageNotFoundPage /> },
    // {
    //   path: "*",
    //   element: <Navigate to={Config?.appBaseURL + "/notfound"} />,
    // },

    // if Not Have Sub Domain
    // { path: "*", element: <PageNotFoundPage /> },
  },
];

import React, { useState } from "react";

//Mui
import { Box, Container, Typography, Button, Tabs, Tab } from "@mui/material";

// Image
import { ReactComponent as File } from "../../../../assets/images/Icons/file.svg";
import { ReactComponent as Arrow } from "../../../../assets/images/Icons/arrow.svg";

// scss
import "./myExpert.scss";

//COMPONENT
import Intro from "./Intro";
import Experience from "./Experience";
import Education from "./Education";
import Counter from "./Counter";

const MyExpertPage = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, value, name) => {
    setValue(value);
  };

  const expertTab = [
    {
      id: 0,
      name: "Intro",
      icon: <Arrow />,
    },
    {
      id: 1,
      name: "Experience",
      icon: <Arrow />,
    },
    {
      id: 2,
      name: "Education",
      icon: <Arrow />,
    },
  ];
  return (
    <>
      <Box className="my-expert-section">
        <Container maxWidth="lg" className="my-expert-wrap">
          <Box className="my-expert-box">
            <Box
              className="left-section  wow
                  animate__animated  animate__slideInLeft"
            >
              <Box
                className="main-title expert-title  wow
                  animate__animated
                  animate__slideInLeft"
              >
                <Typography variant="h3">
                  <span className="expert-text">
                    My expert <br /> areas
                  </span>
                </Typography>
              </Box>
              <Typography
                className="wow
                  animate__animated
                  animate__fadeInUp main-para expert-para mb-30"
              >
                You can express yourself however you want and whenever you want,
                for free. You can customize a template or make your own from
                scratch, with an immersive library at your disposal. You can
                express yourself however you want and whenever you free.
              </Typography>
              <Typography
                className="wow
                  animate__animated
                  animate__fadeInUp main-para expert-para"
              >
                You can customize a template or make your own from scratch, with
                an immersive library at your disposal.
              </Typography>
              <Button
                className="wow
                  animate__animated
                  animate__fadeInUp round-btn expert-btn"
              >
                My Resume <File />
              </Button>
            </Box>
            <Box
              className="wow
                   right-section"
            >
              <Box className="filter-section">
                <Tabs
                  value={value}
                  variant="scrollable"
                  scrollButtons="off"
                  indicatorColor="primary"
                  className="expert-tab-detail wow animate__animated  animate__slideInRight "
                  disableRipple
                  disableFocusRipple
                >
                  {expertTab?.map((item, index) => {
                    return (
                      <Box>
                        <span className="btn">
                          <Tab
                            disableRipple
                            disableFocusRipple
                            label={item?.name}
                            icon={item?.icon}
                            iconPosition="end"
                            value={item?.id}
                            className={item?.id === value ? "active" : ""}
                            onChange={(event, newValue, newName) =>
                              handleChange(event, item?.id, item?.name)
                            }
                          />
                        </span>
                      </Box>
                    );
                  })}
                </Tabs>
              </Box>
              {value === 0 ? (
                <Intro />
              ) : value === 1 ? (
                <Experience />
              ) : value === 2 ? (
                <Education />
              ) : (
                ""
              )}
            </Box>
          </Box>
          <Counter
            data-aos-delay="200"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          />
        </Container>
      </Box>
    </>
  );
};

export default MyExpertPage;

import React from "react";

//MUI
import { Box, Container, Typography, Button, Grid } from "@mui/material";

//ICONS
import { ReactComponent as Arrow } from "../../../../assets/images/Icons/arrow.svg";
import { ReactComponent as Blog } from "../../../../assets/images/Icons/bg-blog.svg";

//SCSS
import "./blogpost.scss";
import { identifiers } from "../../../../helpers/constants/identifier";

const BlogPostSection = () => {
  return (
    <>
      <Box className="blog-main">
        <Container maxWidth="lg">
          <Box>
            <Box className="service-top-wrap">
              {/* <Typography
                variant="h6"
                className="service-text wow animate__animated animate__fadeInLeft"
              >
                <span>
                  From my <br />
                  blog post
                </span>
              </Typography> */}
              <Box className="main-title service-text  wow animate__animated animate__fadeInLeft">
                <Typography variant="h3">
                  <span>
                    From my <br /> blog post
                  </span>
                </Typography>
              </Box>
              <Button
                variant="text"
                className="round-btn view-btn wow animate__animated animate__fadeInRight"
              >
                View All
                <Arrow />
              </Button>
            </Box>
          </Box>
          <Box className="desc-main">
            <Grid container spacing={4} className="blog-grid-cont">
              {identifiers?.blogData?.map((item) => {
                return (
                  <>
                    <Grid item xs={6} className="blog-post-wrap" key={item?.id}>
                      <Box>
                        <ul>
                          <li>
                            <Box className="left-content wow animate__animated animate__fadeInLeft">
                              <Box className="d-flex john-text">
                                <Typography variant="h6">
                                  <span>
                                    <a href="/" className="john-name">
                                      {item?.name}
                                    </a>
                                  </span>
                                </Typography>
                                <Typography
                                  variant="h6"
                                  className="wow
                                  "
                                >
                                  <span>{item?.date}</span>
                                </Typography>
                              </Box>
                              <Typography
                                variant="p"
                                className="desc-left "
                                dangerouslySetInnerHTML={{
                                  __html: item?.desc,
                                }}
                              ></Typography>
                              <Button
                                variant="text"
                                className="read-btn round-btn "
                              >
                                <span className="line"> Read More</span>{" "}
                                <Arrow />
                              </Button>
                            </Box>
                            <Box className="right-content wow animate__animated animate__fadeInRight">
                              <img src={item?.bannerBG} alt="marketing" />
                            </Box>
                            <Box className="shap wow animate__animated animate__fadeInRight">
                              <Blog />
                            </Box>
                          </li>
                        </ul>
                      </Box>
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default BlogPostSection;

import React from "react";
//MUI
import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuList,
  MenuItem,
} from "@mui/material";

// Component
import { NavHashLink } from "react-router-hash-link";
import { Config } from "../../helpers/context/config";
import { identifiers } from "../../helpers/constants/identifier";

const LeftSideBar = () => {
  const scrollWidthOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -118;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <>
      <Box>
        <nav className="main-menu">
          <MenuList>
            {identifiers?.leftSideMenu?.map((item) => (
              <MenuItem key={item?.id}>
                <ListItemText>
                  <NavHashLink
                    smooth
                    to={Config?.appBaseURL + item?.href}
                    scroll={(el) => scrollWidthOffset(el)}
                    activeClassName="selected"
                  >
                    <Box>
                      <ListItemIcon className="icon-menu">
                        {item?.icon}
                      </ListItemIcon>
                    </Box>
                    <Box>{item?.name}</Box>
                  </NavHashLink>
                </ListItemText>
              </MenuItem>
            ))}
          </MenuList>
        </nav>
      </Box>
    </>
  );
};

export default LeftSideBar;

export const saveToStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const fetchFromStorage = (key) => {
  return localStorage.getItem(key);
};

export const removeFromStorage = (key) => {
  localStorage.removeItem(key);
};

export const clearStorage = () => {
  localStorage.clear();
};

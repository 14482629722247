import React, { useState } from "react";
// Mui
import { Box, Grid, Typography } from "@mui/material";

//COUNTUP
import CountUp from "react-countup";

//SCROLL
import ScrollTrigger from "react-scroll-trigger";

const Counter = () => {
  const [counter, setcounter] = useState(false);
  return (
    <>
      <ScrollTrigger
        onEnter={() => setcounter(true)}
        onExit={() => setcounter(false)}
      >
        <Box className="counter-section">
          <Grid container className="Counter-cont">
            <Grid
              item
              lg={3}
              className="counter-box wow
                  animate__animated
                  animate__fadeInUp"
            >
              <Box className="counter-list">
                <Typography variant="h4">
                  {counter && (
                    <CountUp start={0} end={219} duration={2} delay={0} />
                  )}
                </Typography>
                <Typography className="title">Trainings</Typography>
              </Box>
            </Grid>
            <Grid
              item
              lg={3}
              className="wow
                  animate__animated
                  animate__fadeInUp counter-box"
            >
              <Box className="counter-list">
                <Typography variant="h4">
                  {" "}
                  {counter && (
                    <CountUp start={0} end={48} duration={2} delay={0} />
                  )}
                </Typography>
                <Typography className="title">Written book</Typography>
              </Box>
            </Grid>
            <Grid
              item
              lg={3}
              className="wow
                  animate__animated
                  animate__fadeInUp counter-box"
            >
              <Box className="counter-list">
                <Typography variant="h4">
                  {" "}
                  {counter && (
                    <CountUp start={0} end={527} duration={2} delay={0} />
                  )}
                </Typography>
                <Typography className="title">Listeners</Typography>
              </Box>
            </Grid>
            <Grid
              item
              lg={3}
              className="wow
                  animate__animated
                  animate__fadeInUp counter-box"
            >
              <Box className="counter-list">
                <Typography variant="h4">
                  {" "}
                  {counter && (
                    <CountUp start={0} end={12} duration={2} delay={0} />
                  )}
                </Typography>
                <Typography className="title">Own projects</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ScrollTrigger>
    </>
  );
};

export default Counter;

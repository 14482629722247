import React from "react";
// import ReactLoading from "react-loading";
import "./loader.scss";

const Loader = () => (
  <>
    {/* <span className="spinner">
      <ReactLoading type="bars" color="#439a97" />
    </span> */}
    <div id="preloader">
      <div class="loader_line"></div>
    </div>
  </>
);

export default Loader;

import React from "react";

//MUI
import { Box, Container, Grid, Typography } from "@mui/material";

//IMG&ICONS
import Success from "../../../../assets/images/success.png";
import { ReactComponent as Bottom } from "../../../../assets/images/Icons/achivmnetsleft.svg";
import { ReactComponent as Top } from "../../../../assets/images/Icons/achivmnetsRight.svg";

//COMPONENTS
import { identifiers } from "../../../../helpers/constants/identifier";

//SCSS
import "./achivements.scss";

const Achievements = () => {
  return (
    <>
      <Box className="sucess-top">
        <Container maxWidth="lg">
          <Grid container spacing={2} className="media-main">
            <Grid item sm={12} md={6} lg={6} xl={6}>
              <Box>
                <Box
                  className="service-top-wrap wow
                        animate__animated animate__fadeInBottomLeft"
                >
                  <Box
                    className="main-title service-text wow
                        animate__animated animate__fadeInBottomLeft"
                  >
                    <Typography variant="h3">
                      <span>My Achievements</span>
                    </Typography>
                  </Box>
                </Box>
                <Box className="success-img-top">
                  <img
                    src={Success}
                    alt="success"
                    className="success-img wow
                        animate__animated animate__fadeInUp"
                  />
                  <Box className="bottom-icon">
                    <Bottom />
                  </Box>
                  <Box className="top-icon">
                    <Top />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item sm={12} md={6} lg={6} xl={6}>
              <Box className="all-text-wrap">
                {identifiers?.successdata?.map((item) => {
                  return (
                    <>
                      {" "}
                      <Box className="desc-main">
                        <Typography
                          variant="span"
                          className=" wow animate__animated animate__fadeInUp number-year"
                        >
                          {item?.year}
                        </Typography>
                        <Typography
                          variant="h5"
                          className=" wow animate__animated animate__fadeInUp best-text"
                        >
                          {item?.title}
                        </Typography>
                        <Typography
                          variant="h6"
                          className="wow animate__animated animate__fadeInUp right-text-wrap"
                        >
                          {item?.desc}
                        </Typography>
                      </Box>
                    </>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Achievements;

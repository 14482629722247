import React from "react";
import { Outlet } from "react-router-dom";
// component
// import Header from "../../views/component/Header/Header";
// import Footer from "../../views/component/Footer/Footer";
// import Loader from "../../components/Loader";
// mui
import { Box } from "@mui/material";
// wow
// import { WOW } from "wowjs";
// img
// import { ReactComponent as ArrowBarUP } from "../../assets/image/icon/arrowBarUP.svg";

// PrivateLayout

const PrivateLayout = () => {
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const wow = new WOW({ live: false }); // disables sync requirement
  //   wow.init();
  // }, [loading]);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);
  //   return () => clearTimeout(timer);
  // }, []);

  return (
    <Box className="BG-Body">
      {/* {loading ? (
        <Box className="loader">
          <Loader />
        </Box>
      ) : ( */}
      <>
        {/* <Header /> */}
        <Box className="main-content">
          <Outlet />
        </Box>
        {/* <Footer /> */}
      </>
      {/* )} */}
    </Box>
  );
};

export default PrivateLayout;

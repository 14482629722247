import React from "react";

//COMPONENT
import Page from "../../../components/Page.js";
import Home from "../../component/Home/Home";

const HomePage = () => {
  return (
    <Page title="Home">
      <Home />
    </Page>
  );
};

export default HomePage;

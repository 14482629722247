import React, { useLayoutEffect, useContext, useState, useEffect } from "react";
// Mui
import {
  Box,
  AppBar,
  IconButton,
  Button,
  Toolbar,
  Collapse,
  List,
  ListItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
// image
import Logo from "../../../assets/images/logo.png";
import { ReactComponent as LightTheme } from "../../../assets/images/Icons/lightIcon.svg";
import { ReactComponent as DarkIcon } from "../../../assets/images/Icons/darkIcon.svg";

// component
import { Config } from "../../../helpers/context/config";
import { NavHashLink } from "react-router-hash-link";
import { ThemeContext } from "../../../helpers/context/theme";
import DarkLogo from "../../../assets/images/darkLogo.png";
import { identifiers } from "../../../helpers/constants/identifier";
import clsx from "clsx";
// scss
import "./header.scss";

// helpers
const capitalize = (text: string) =>
  text.charAt(0).toUpperCase() + text.substr(1);

const clamp = (value: number) => Math.max(0, value);

const isBetween = (value: number, floor: number, ceil: number) =>
  value >= floor && value <= ceil;

// hooks
const useScrollspy = (ids: string[], offset: number = 0) => {
  const [activeId, setActiveId] = useState("");

  useLayoutEffect(() => {
    const listener = () => {
      const scroll = window.pageYOffset;

      const position = ids
        .map((id) => {
          const element = document.getElementById(id);

          if (!element) return { id, top: -1, bottom: -1 };

          const rect = element.getBoundingClientRect();
          const top = clamp(rect.top + scroll - offset);
          const bottom = clamp(rect.bottom + scroll - offset);

          return { id, top, bottom };
        })
        .find(({ top, bottom }) => isBetween(scroll, top, bottom));

      setActiveId(position?.id || "");
    };

    listener();

    window.addEventListener("resize", listener);
    window.addEventListener("scroll", listener);

    return () => {
      window.removeEventListener("resize", listener);
      window.removeEventListener("scroll", listener);
    };
  }, [ids, offset]);

  return activeId;
};

const Header = () => {
  const activeId = useScrollspy(identifiers?.ids, 50);
  const [mobileOpen, setmobileOpen] = useState(false);
  const [Isscroll, setISscroll] = useState(false);
  const { theme, toggle } = useContext(ThemeContext);
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    setISscroll(scrollPosition > 90);
  };
  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollWidthOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -118;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <>
      <Box className="header-wrap">
        <AppBar
          component="nav"
          className={`${Isscroll ? "fixed" : "static"} header-appbar`}
        >
          <Toolbar className="header-menu">
            <Box className="logo">
              <a href={Config?.appBaseURL}>
                {theme === "light" ? (
                  <img src={Logo} alt="Logo" height="98px" />
                ) : (
                  <img src={DarkLogo} alt="Logo" height="98px" />
                )}
              </a>
            </Box>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <Box className="menu-list">
                {/* {identifiers?.menuData?.map((item) => (
                  <NavHashLink
                    key={item?.id}
                    smooth
                    to={Config?.appBaseURL + item?.href}
                    scroll=g{(el) => scrollWidthOffset(el)}
                    activeClassName="selected"
                  >
                   {item?.name}</span>
                  </NavHashLink>
                ))} */}
                {identifiers?.ids.map((id) => (
                  <NavHashLink
                    to={`#${id}`}
                    smooth
                    key={`menu-item-${id}`}
                    // activeClassName="selected"
                    scroll={(el) => scrollWidthOffset(el)}
                    className={clsx(
                      "menu-link",
                      id === activeId && "menu-link-active"
                    )}
                  >
                    <span className="line">{capitalize(id)}</span>
                  </NavHashLink>
                ))}
              </Box>
            </Box>
            <IconButton
              aria-label="open drawer"
              edge="start"
              sx={{ display: { xs: "none", md: "block" } }}
              onClick={() => {
                toggle();
              }}
              className="theme-btn"
            >
              {theme === "light" ? <DarkIcon /> : <LightTheme />}
            </IconButton>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <Button variant="contained" className="round-btn">
                Contact Now
              </Button>
            </Box>
            <IconButton
              className="menu-icon"
              color="inherit"
              aria-label="open drawer"
              edge="start"
              sx={{ mr: 2, display: { md: "none" } }}
            >
              {mobileOpen ? (
                <CloseIcon onClick={() => setmobileOpen(false)} />
              ) : (
                <MenuIcon onClick={() => setmobileOpen(true)} />
              )}
            </IconButton>
          </Toolbar>
        </AppBar>
        <Collapse
          in={mobileOpen}
          timeout="auto"
          unmountOnExit
          className="mobile-menu"
          sx={{ mr: 2, display: { md: "none" } }}
        >
          <List component="div" disablePadding>
            {identifiers?.menuData?.map((item) => (
              <ListItem>
                <NavHashLink
                  key={item?.id}
                  smooth
                  to={Config?.appBaseURL + item?.href}
                  scroll={(el) => scrollWidthOffset(el)}
                  activeClassName="selected"
                  onClick={() => setmobileOpen(false)}
                >
                  {item?.name}
                </NavHashLink>
              </ListItem>
            ))}
          </List>
        </Collapse>
      </Box>
    </>
  );
};

export default Header;

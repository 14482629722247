import React from "react";

// Mui
import { Box, Grid, Typography } from "@mui/material";

//COMPONENT
import { identifiers } from "../../../../../helpers/constants/identifier";

const Intro = () => {
  return (
    <>
      <Grid container className="intro-container">
        {identifiers?.IntroData?.map((item) => (
          <Grid item lg={4} key={item?.id} className="single-intro-box">
            <Box className="intro-list">
              <span
                className="icon wow
                  animate__animated
                  animate__fadeInUp"
              >
                <span className="icon-in ">{item?.icon}</span>
              </span>
              <Typography
                className="wow
                  animate__animated
                  animate__fadeInUp"
              >
                {item?.name}
                <span>({item?.percentage})</span>
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Intro;

import React, { forwardRef } from "react";
import { Helmet } from "react-helmet";

import PropTypes from "prop-types";
// mui
import { Box } from "@mui/material";

const Page = forwardRef(
  ({ children, title = "", description = "", ...rest }, ref) => {
    return (
      <>
        <Box ref={ref} {...rest}>
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <main>{children}</main>
        </Box>
      </>
    );
  }
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default Page;

import React, { useState, useEffect, useContext } from "react";

// Mui
import { Box, Grid, Typography, Button, Link } from "@mui/material";

// image
import { ReactComponent as Hand } from "../../../../assets/images/Icons/hand.svg";
import { ReactComponent as ArrowRight } from "../../../../assets/images/Icons/arrowRight.svg";
import { ReactComponent as Arrow } from "../../../../assets/images/Icons/arrow.svg";
import { ReactComponent as Facebook } from "../../../../assets/images/Icons/facebookDark.svg";
import { ReactComponent as Instagram } from "../../../../assets/images/Icons/instagramDark.svg";
import { ReactComponent as LinkedIn } from "../../../../assets/images/Icons/linkedInDark.svg";
import { ReactComponent as Play } from "../../../../assets/images/Icons/play.svg";
import { ReactComponent as Mouse } from "../../../../assets/images/Icons/mouse.svg";
import BannerLight from "../../../../assets/images/lightBanner.png";
import BannerDark from "../../../../assets/images/darkBanner.png";
import VideoBanner from "../../../../assets/images/videoBanner.png";

//COMPONENT
import DialogBox from "../../../Ul/dailogbox";
import { ThemeContext } from "../../../../helpers/context/theme";

//SCSS
import "./banner.scss";

const BannerPage = () => {
  const { theme } = useContext(ThemeContext);
  const [open, setOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const handleClose = () => {
    setOpen(!open);
  };

  const scrollDown = () => {
    // const violation = document.getElementById("about");
    // violation.scrollIntoView({ block: "start", behavior: "smooth" });
    var element = document.getElementById("about");
    var headerOffset = 150;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Box className="banner-section">
        <Box className="banner-container page-wrap">
          <Grid container className="banner-container-wrap">
            <Grid
              item
              lg={5}
              className="left-banner wow animate__animated animate__fadeInBottomLeft"
            >
              <Box className="content-box">
                <Typography
                  className="wow animate__animated animate__fadeInUp"
                  variant="h1"
                >
                  John Smith{" "}
                  <span className="hand-emoji">
                    <Hand />
                  </span>
                </Typography>
                <Typography
                  className="wow animate__animated animate__fadeInUp job"
                  variant="h6"
                >
                  Visual Designer
                </Typography>
                <Typography className="wow animate__animated animate__fadeInUp">
                  I'm creative designer based in New York, and I'm <br /> very
                  passionate and dedicated to my work.
                </Typography>

                <Button variant="contained" className="round-btn banner-btn">
                  Say Hello <ArrowRight />
                </Button>
                <Button variant="text" className="round-btn work-btn">
                  <span className="line"> My Works</span>
                  <Arrow />
                </Button>
              </Box>
              <Box className="follow-me d-flex banner-follow-me wow animate__animated animate__fadeInUp">
                <Typography className="mr-20">Follow me on:</Typography>
                <ul className="d-flex">
                  <li className="icon-button mr-20">
                    <Link
                      target="_ "
                      href="https://www.linkedin.com/company/jnextservices/"
                    >
                      <LinkedIn />
                    </Link>
                  </li>
                  <li className="icon-button mr-20">
                    <Link
                      target="_ "
                      href="https://www.facebook.com/jnextservice"
                    >
                      <Facebook />
                    </Link>
                  </li>
                  <li className="icon-button mr-20">
                    <Link
                      target="_ "
                      href="https://www.instagram.com/jnextservices/"
                    >
                      <Instagram className="instagram" />
                    </Link>
                  </li>
                </ul>
              </Box>
            </Grid>
            <Grid
              item
              lg={7}
              className="right-banner wow animate__animated animate__fadeInBottomRight"
            >
              {theme === "light" ? (
                <img
                  src={BannerLight}
                  alt="bannerimg"
                  className="banner-img "
                />
              ) : (
                <img src={BannerDark} alt="bannerimg" className="banner-img" />
              )}

              <Box className="video-button">
                <a onClick={() => handleClose()}>
                  <img src={VideoBanner} alt="video" />
                  <Play />
                </a>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className="mouse-icon">
          <a onClick={(e) => scrollDown(e)}>
            <Mouse />
          </a>
        </Box>
      </Box>
      <DialogBox
        handleClose={handleClose}
        open={open}
        content={
          <>
            {screenWidth > 767 ? (
              <Box className="video-secton">
                <iframe
                  width="600"
                  height="315"
                  src="https://www.youtube.com/embed/NpEaa2P7qZI"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </Box>
            ) : (
              <Box className="video-secton">
                <iframe
                  width="300"
                  height="315"
                  src="https://www.youtube.com/embed/NpEaa2P7qZI"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </Box>
            )}
          </>
        }
      />
    </>
  );
};

export default BannerPage;

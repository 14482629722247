import React, { useState, useRef, useEffect } from "react";

// mui
import { Box } from "@mui/material";

//COMPONENT
import Header from "../../views/component/Header/";
import Footer from "../../views/component/Footer";
import LeftSideBar from "../LeftSidebar";
import Loader from "../../components/Loader";
import { WOW } from "wowjs";
import { Outlet } from "react-router-dom";

//SCSS
import "../layout.scss";
import "animate.css";

const PublicLayout = () => {
  const [progress, setprogress] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    var wow = new WOW({
      live: false,
    });
    wow.init();
  }, [loading]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 999);
    return () => clearTimeout(timer);
  }, []);
  const progressBar = () => {
    var winScroll = document.documentElement.scrollTop;
    var height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    var scrolled = (winScroll / height) * 100;
    setprogress(scrolled > 0 ? true : false);
    document.getElementById("myBar").style.height = scrolled + "%";
  };

  window.onscroll = progressBar;

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Box className="layout-backGround">
      {loading ? (
        <Box className="loader">
          <Loader />
        </Box>
      ) : (
        // <></>
        <>
          {/* <Loader /> */}
          <Box
            className="progressbar"
            style={
              progress
                ? {
                    display: "block",
                  }
                : { display: "none" }
            }
          >
            <a href="#">
              <span className="to-top-text" onClick={(e) => scrollTop(e)}>
                To Top
              </span>
            </a>
            <span className="to-top-line" id="myBar"></span>
          </Box>
          <Box>
            <LeftSideBar />
          </Box>
          <Header />
          <Box className="main-content">
            <Outlet />
          </Box>
          <Footer />
        </>
      )}
    </Box>
  );
};

export default PublicLayout;

import React, { useContext, useState, useEffect, useRef } from "react";

//MUI
import { Box, Container, Typography, Button } from "@mui/material";

//ICONS
import { ReactComponent as Arrow } from "../../../../assets/images/Icons/arrow.svg";
import { ReactComponent as LeftArrow } from "../../../../assets/images/Icons/leftArrow.svg";
import { ReactComponent as RightArrow } from "../../../../assets/images/Icons/RightArrow.svg";

//Component
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//SCSS
import "./feedback.scss";
import { ReactComponent as LeftArrowDark } from "../../../../assets/images/Icons/leftWhite.svg";
import { ReactComponent as RightArrowDark } from "../../../../assets/images/Icons/rightWhite.svg";
import { identifiers } from "../../../../helpers/constants/identifier";

import { ThemeContext } from "../../../../helpers/context/theme";

const FeedBack = () => {
  const sliderRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const { theme } = useContext(ThemeContext);
  const settings = {
    infinite: true,
    speed: 500,
    slickGoTo: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    cssEase: "linear",
    touchMove: true,
    dots: true,
    draggable: true,
    variableWidth: true,
    beforeChange: (current, next) => setActiveSlide(next),
    prevArrow: (
      <div class="slick-prev">
        <LeftArrow />
      </div>
    ),
    nextArrow: (
      <div class="slick-next" onClick={() => sliderRef.current.slickGoTo(5)}>
        <RightArrow />
      </div>
    ),
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1259,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
    ],
  };

  useEffect(() => {
    if (window.innerWidth > 1400 && activeSlide > 1) {
      sliderRef.current.slickGoTo(5);
    }
  }, [activeSlide]);
  return (
    <>
      <Box className="feedback-section">
        <Container maxWidth="lg">
          <Box className="service-top-wrap feedback-top-text">
            {/* <Typography
              variant="h6"
              className="service-text wow
              animate__animated animate__fadeInLeft"
            >
              <span>
                Valuable feedback <br />
                from my clients
              </span>
            </Typography> */}
            <Box
              className="main-title service-text wow
              animate__animated animate__fadeInLeft"
            >
              <Typography variant="h3">
                <span className="feedback-text">
                  Valuable feedback <br /> from my clients
                </span>
              </Typography>
            </Box>
            <Button
              variant="contained"
              className="round-btn view-btn wow
                        animate__animated animate__fadeInRight"
            >
              View All <Arrow />
            </Button>
          </Box>
        </Container>
        <Box className="feedback-bottom">
          <Slider {...settings} ref={sliderRef}>
            {identifiers?.feedBackData?.map((item) => (
              <div>
                <Box className="d-flex feedback-slide " key={item?.id}>
                  <img
                    src={item?.img}
                    alt="client"
                    className="wow
                        animate__animated animate__fadeInUp"
                  />
                  <Box className="feedback-details ">
                    <Typography
                      variant="p"
                      className="feedback-desc wow
                        animate__animated animate__fadeInUp"
                    >
                      {item?.details}
                    </Typography>
                    <Box className="feedback-name">
                      <Typography
                        className="name wow
                        animate__animated animate__fadeInUp"
                      >
                        {item?.name}
                      </Typography>
                      <Typography
                        className="postion wow
                        animate__animated animate__fadeInUp"
                      >
                        {item?.postion}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </div>
            ))}
          </Slider>
        </Box>
      </Box>
    </>
  );
};

export default FeedBack;

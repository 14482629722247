import React from "react";

//MUI
import { Box, Container, Typography, Button } from "@mui/material";

//ICONS
import { ReactComponent as Arrow } from "../../../../assets/images/Icons/arrow.svg";

//SCSS
import "./service.scss";
import { identifiers } from "../../../../helpers/constants/identifier";

const ServiceSection = () => {
  return (
    <>
      <Box className="service-section">
        <Container maxWidth="lg">
          <Box className="service-top-wrap service-top">
            <Box
              className="main-title service-text wow
              animate__animated
              animate__fadeInLeft"
            >
              <Typography variant="h3">
                <span>
                  Services <br /> I Provide
                </span>
              </Typography>
            </Box>
            <Button
              variant="contained"
              className="wow
                        animate__animated
                        animate__fadeInRight round-btn explore-btn"
            >
              Explore <Arrow />
            </Button>
          </Box>
          <Box className="service-list">
            <ul>
              {identifiers?.ServiceData?.map((item) => {
                return (
                  <>
                    <li>
                      <Box className="left-content">
                        <Typography
                          variant="p"
                          className="number wow
                        animate__animated
                        animate__fadeInLeft"
                        >
                          {item?.number}
                        </Typography>
                        <Typography
                          variant="h6"
                          className="title number wow
                        animate__animated
                        animate__fadeInLeft"
                        >
                          {item?.title}
                        </Typography>
                      </Box>
                      <Box className="right-content">
                        <Typography
                          variant="h6"
                          className="right-text  number wow
                        animate__animated
                        animate__fadeInRight"
                        >
                          {item?.desc}
                        </Typography>
                        <Box
                          className="arrow number wow
                        animate__animated
                        animate__fadeInRight"
                        >
                          <Arrow />
                        </Box>
                      </Box>
                    </li>
                  </>
                );
              })}
            </ul>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ServiceSection;

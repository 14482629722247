import React, { useContext } from "react";
import { useRoutes } from "react-router-dom";
import { Box } from "@mui/material";
import { publicRoute, privateRoute } from "../src/routes";
import "../src/assets/scss/global.scss";
import { ThemeContext } from "./helpers/context/theme";

const App = () => {
  // const [theme, setTheme] = useState(fetchFromStorage("theme"));
  const localAuth = JSON.parse(localStorage.getItem("auth_token"));
  const viewRoutes = localAuth ? privateRoute : publicRoute; // Condition for show public and private route base on login, defult is public route
  const routing = useRoutes(viewRoutes);
  const { theme } = useContext(ThemeContext);

  return (
    <>
      <Box data-theme={theme}>{routing}</Box>
    </>
  );
};

export default App;

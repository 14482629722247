import React from "react";

// Mui
import { Box, Button, Container, Typography } from "@mui/material";

// Image
import { ReactComponent as Element } from "../../../../assets/images/Icons/element.svg";
import { ReactComponent as File } from "../../../../assets/images/Icons/file.svg";
import { ReactComponent as Signature } from "../../../../assets/images/Icons/signature.svg";

// scss
import "./index.scss";

const AboutMePage = () => {
  return (
    <>
      <Box className="about-me-section">
        <Container maxWidth="lg">
          <Box className="about-wrap">
            <Box className="about-me-box d-flex">
              <Box className="left-content">
                <Box className="left-content-box" data-aos="fade-right">
                  <Typography
                    variant="h6"
                    className="wow
                  animate__animated
                  animate__fadeInUp"
                  >
                    10
                  </Typography>
                  <Typography
                    className="wow
                      animate__animated
                      animate__fadeInUp"
                  >
                    Years of Experience
                  </Typography>
                  <Typography
                    variant="h6"
                    className="person-name  wow
                  animate__animated
                  animate__fadeInUp"
                  >
                    John Smith
                  </Typography>
                </Box>
              </Box>
              <Box className="right-content">
                <span className="element">
                  <Element />
                </span>
                <Box className="main-title">
                  <Typography
                    variant="h3"
                    className="wow
                  animate__animated
                  animate__fadeInUp"
                  >
                    <span>About Me</span>
                  </Typography>
                </Box>
                <Typography
                  className="main-para wow
                  animate__animated
                  animate__fadeInUp"
                >
                  You can express yourself however you want and whenever you
                  want, for free. You can customize a template or make your own
                  from scratch, with an immersive library at your disposal.
                </Typography>
                <Box
                  className="d-flex btn-group wow
                  animate__animated
                  animate__fadeInUp"
                >
                  <Button className="round-btn about-me-btn">
                    Download My CV <File />
                  </Button>
                  <Signature />
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default AboutMePage;

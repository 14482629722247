import React, { useState, useEffect } from "react";
//MUI
import { Box, Button, Container, Link, Typography, Grid } from "@mui/material";
//ICONS
import { ReactComponent as ArrowRight } from "../../../assets/images/Icons/arrowRight.svg";
import { ReactComponent as FooterLogo } from "../../../assets/images/Icons/FooterLogo.svg";
import { ReactComponent as Facebook } from "../../../assets/images/Icons/facebook.svg";
import { ReactComponent as Instagram } from "../../../assets/images/Icons/instagram.svg";
import { ReactComponent as LinkedIn } from "../../../assets/images/Icons/linkedIn.svg";
//IMAGES
import Video from "../../../assets/images/Video.png";
import BgImage from "../../../assets/images/bg-footer.png";
import BgImage1 from "../../../assets/images/bg-footer-1.png";
import { ReactComponent as PlayBlack } from "../../../assets/images/Icons/playBlack.svg";
// component
import DialogBox from "../../Ul/dailogbox";
//SCSS
import "./footer.scss";

const Footer = () => {
  const [open, setOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const handleClose = () => {
    setOpen(!open);
  };
  return (
    <>
      <Box className="footer-top" id="contact">
        <Container maxWidth="lg">
          <Box className="footer-main-wrap">
            <Grid
              container
              spacing={0}
              className="footer-desc wow animate__animated animate__fadeInUp"
            >
              <Grid item lg={2} sm={12} md={2}>
                <Box className="video-button-footer wow animate__animated animate__fadeInUp">
                  <a onClick={() => handleClose()}>
                    <img src={Video} alt="video" />
                    <PlayBlack />
                  </a>
                </Box>
              </Grid>
              <Grid item lg={7} sm={12} md={7} className="text-wrap">
                <Box className="text">
                  <Typography
                    variant="h3"
                    className="wow animate__animated animate__fadeInUp"
                  >
                    Let's work together
                  </Typography>
                  <Typography
                    variant="p"
                    className="wow animate__animated animate__fadeInUp"
                  >
                    You can express yourself however you want and whenever you
                    want, for free. You can customize a template or make your
                    own.
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                lg={3}
                sm={12}
                md={3}
                className="text-end text-btn wow animate__animated animate__fadeInUp"
              >
                <Button variant="contained" className="round-btn hello-btn ">
                  Say Hello <ArrowRight />
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box className="d-flex social-icon wow animate__animated animate__fadeInUp">
            <Box className="left-content ">
              <Box>
                <Typography className="name wow animate__animated animate__fadeInUp">
                  Call:
                </Typography>
                <Typography className="details-text wow animate__animated animate__fadeInUp">
                  094265 86997
                </Typography>
              </Box>
              <Box className="center-content ">
                <Typography className="name wow animate__animated animate__fadeInUp">
                  Email:
                </Typography>
                <Typography className="wow animate__animated animate__fadeInUps">
                  <Link href="mailto:info@jnext.co.in" className="details-text">
                    info@jnext.co.in
                  </Link>
                </Typography>
              </Box>
            </Box>
            <Box className="right-content d-flex wow animate__animated animate__fadeInUp">
              <Typography className="mr-20">Follow me on:</Typography>
              <ul className="d-flex">
                <li className="icon-button mr-20 wow animate__animated animate__fadeInUp">
                  <Link
                    target="_ "
                    href="https://www.linkedin.com/company/jnextservices/"
                  >
                    <LinkedIn />
                  </Link>
                </li>
                <li className="icon-button mr-20 wow animate__animated animate__fadeInUp">
                  <Link
                    target="_ "
                    href="https://www.facebook.com/jnextservice"
                  >
                    <Facebook />
                  </Link>
                </li>
                <li className="icon-button mr-20 wow animate__animated animate__fadeInUp">
                  <Link
                    target="_ "
                    href="https://www.instagram.com/jnextservices/"
                  >
                    <Instagram className="insta" />
                  </Link>
                </li>
              </ul>
            </Box>
          </Box>
        </Container>
        <Box className="bg-left">
          <img src={BgImage} alt="bg-img" />
        </Box>
        <Box className="bg-right">
          <img src={BgImage1} alt="bg-img-1" />
        </Box>
      </Box>
      <Box className="footer-bottom ">
        <Container maxWidth="lg" className="footer-bottom-wrap">
          <Link
            href="#"
            className="footer-logo-text wow animate__animated animate__zoomIn"
          >
            <FooterLogo />
          </Link>
          <Box>
            <Typography
              variant="p"
              className="copy-text wow animate__animated animate__zoomIn"
            >
              &copy; {new Date().getFullYear()} -{" "}
              <Link
                href="https://www.jnext.co.in/"
                target="_ "
                className="link-text"
              >
                JNext Services Pvt. Ltd.{" "}
              </Link>{" "}
              All Rights Reserved
            </Typography>
          </Box>
        </Container>
      </Box>
      <DialogBox
        handleClose={handleClose}
        open={open}
        content={
          <>
            {screenWidth > 767 ? (
              <Box className="video-secton">
                <iframe
                  width="600"
                  height="315"
                  src="https://www.youtube.com/embed/NpEaa2P7qZI"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </Box>
            ) : (
              <Box className="video-secton">
                <iframe
                  width="300"
                  height="315"
                  src="https://www.youtube.com/embed/NpEaa2P7qZI"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </Box>
            )}
          </>
        }
      />
    </>
  );
};

export default Footer;

import React, { useState, useEffect, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
// Mui
import { Box } from "@mui/material";
//COMPONENTS
import Achievements from "./Achievements";
import BannerPage from "./Banner";
import FeedBack from "./FeedBack";
import ServiceSection from "./ServiceProvide";
import AboutMePage from "./AboutMe";
import MyExpertPage from "./MyExpert";
import Project from "./Project";
import BlogPostSection from "./BlogPost";
import OurClient from "./OurClient";
import { identifiers } from "../../../helpers/constants/identifier";

const Home = () => {
  const location = useLocation();
  const [timeInterval, setTimeInterval] = useState(true);
  const goToViolation = (classId) => {
    const violation = document.getElementById(classId);
    window.scrollTo({
      top: violation?.offsetTop,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const url = window.location.href;
    const spliturl = url.split("#");
    const id = spliturl[spliturl.length - 1];
    var redirectId = id ? id : location?.state?.sectionId;
    setTimeout(() => {
      redirectId && goToViolation(redirectId);
    }, 800);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setTimeInterval(!timeInterval);
    }, 800);
  }, [timeInterval]);
  return (
    <>
      <Box id="home">
        <BannerPage />
      </Box>

      <Box id="about">
        <AboutMePage />
      </Box>

      <MyExpertPage />

      <Box id="service">
        <ServiceSection />
      </Box>

      <Box id="portfolio">
        <Project />
      </Box>

      <FeedBack />

      <Achievements />

      <Box id="blog">
        <BlogPostSection />
      </Box>

      <OurClient />
    </>
  );
};

export default Home;

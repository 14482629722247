import React from "react";
// mui
import { Box, Grid } from "@mui/material";
// image
//Component
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// scss
import "./ourClient.scss";
import { identifiers } from "../../../../helpers/constants/identifier";

const OurClient = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: true,
    cssEase: "linear",
    touchMove: true,
    draggable: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 690,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2200,
        },
      },
      {
        breakpoint: 460,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Box className="client-section">
        <Grid container>
          <Box className="client-container">
            <Box className="client-wrap">
              <Slider {...settings}>
                {identifiers?.clientData?.map((item) => (
                  <Grid
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-delay="200"
                    item
                    sm={4}
                    className="single-slide"
                    key={item?.id}
                  >
                    {item?.img}
                  </Grid>
                ))}
              </Slider>
            </Box>
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default OurClient;

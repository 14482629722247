import React, { createContext, useState, useEffect } from "react";
import { fetchFromStorage, saveToStorage } from "./storage";
import { Config } from "./config";

const initialState = {
  theme: Config?.default_Theme,
  toggle: () => {},
};
const ThemeContext = createContext(initialState);

function ThemeProvider({ children }) {
  const [theme, setTheme] = useState(fetchFromStorage("theme"));

  useEffect(() => {
    const newTheme = fetchFromStorage("theme");
    saveToStorage("theme", !newTheme ? Config?.default_Theme : theme);
    setTheme(!newTheme ? Config?.default_Theme : theme);
  }, []);

  const toggle = () => {
    saveToStorage("theme", theme === "dark" ? "light" : "dark");
    setTheme(theme === "dark" ? "light" : "dark");
  };

  return (
    <ThemeContext.Provider value={{ theme, toggle }}>
      {children}
    </ThemeContext.Provider>
  );
}

export { ThemeProvider, ThemeContext };

import React from "react";
import { Dialog, Grow } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./dailogbox.scss";

const DialogBox = ({ handleClose, open, content, type }) => {
  return (
    <Dialog
      className={`${type} custom-dialog`}
      onClose={handleClose}
      open={open}
      TransitionComponent={Grow}
      scroll={"paper"}
    >
      <CloseIcon className="dialog-close" onClick={handleClose} />
      {content}
    </Dialog>
  );
};

export default DialogBox;

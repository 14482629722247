import React from "react";

// Mui
import { Box, Container, Typography, Button, Grid } from "@mui/material";

// Image
import { ReactComponent as Arrow } from "../../../../assets/images/Icons/arrow.svg";
import { ReactComponent as LeftArrow } from "../../../../assets/images/Icons/leftArrow.svg";
import { ReactComponent as RightArrow } from "../../../../assets/images/Icons/RightArrow.svg";

//Component
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { identifiers } from "../../../../helpers/constants/identifier";

// sccs
import "./project.scss";

const Project = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: true,
    cssEase: "linear",
    touchMove: true,
    draggable: true,
    prevArrow: (
      <div className="slick-prev">
        <LeftArrow />
      </div>
    ),
    nextArrow: (
      <div className="slick-next">
        <RightArrow />
      </div>
    ),
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          autoplay: true,
          autoplaySpeed: 2500,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Box className="project-section">
        <Container maxWidth="lg" className="project-wrap">
          <Box className="project-title-box d-flex">
            <Box
              className="main-title project-title wow
                        animate__animated animate__fadeInBottomLeft"
            >
              <Typography variant="h3">
                <span>
                  Look at my <br /> recent projects
                </span>
              </Typography>
            </Box>
            <Box
              className="project-btn-group wow
                        animate__animated animate__fadeInBottomRight"
            >
              <Button className="round-btn project-btn">
                All <Arrow />
              </Button>
              <Button className="round-btn project-btn">
                Branding <Arrow />
              </Button>
              <Button className="round-btn project-btn">
                Social Media Marketing <Arrow />
              </Button>
            </Box>
          </Box>
          <Box className="slider-section">
            <Slider {...settings}>
              {identifiers?.projectData?.map((item) => (
                <Grid container className="slide-cont">
                  <Grid item lg={4} key={item?.id} className="slide-single-box">
                    <Box
                      className="project-img wow
                        animate__animated animate__fadeInUp"
                    >
                      <img src={item?.image} alt="project name" />
                    </Box>
                    <Typography
                      variant="h6"
                      className="project-content wow
                        animate__animated animate__fadeInUp"
                    >
                      {item?.name}
                    </Typography>
                    <Typography
                      variant="h5"
                      className="project-name wow
                        animate__animated animate__fadeInUp"
                    >
                      <a target="#">{item?.details}</a>
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Slider>
          </Box>
          <Box className="text-center">
            <Button
              className="round-btn explore-btn wow
                        animate__animated animate__fadeInUp"
            >
              Explore More <Arrow />
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Project;

//Images
import Marketing from "../../assets/images/marketing.png";
import Mistake from "../../assets/images/mistake.png";
import solution from "../../assets/images/solution.png";
import people from "../../assets/images/people.png";

import Client from "../../assets/images/client.png";
import Client1 from "../../assets/images/client1.png";

import FoodDelivery from "../../assets/images/foodDelivery.png";
import CarExport from "../../assets/images/carExport.png";
import Ecommerce from "../../assets/images/ecommerce.png";

import { ReactComponent as Client12 } from "../../assets/images/Icons/client1.svg";
import { ReactComponent as Client2 } from "../../assets/images/Icons/client2.svg";
import { ReactComponent as Client3 } from "../../assets/images/Icons/client3.svg";

// Image
import { ReactComponent as Figma } from "../../assets/images/Icons/figma.svg";
import { ReactComponent as Ae } from "../../assets/images/Icons/ae.svg";
import { ReactComponent as Ps } from "../../assets/images/Icons/ps.svg";
import { ReactComponent as Xd } from "../../assets/images/Icons/xd.svg";
import { ReactComponent as Ai } from "../../assets/images/Icons/ai.svg";
import { ReactComponent as Id } from "../../assets/images/Icons/id.svg";

// left side Menu
import { ReactComponent as Home } from "../../assets/images/Icons/home.svg";
import { ReactComponent as Bucket } from "../../assets/images/Icons/bucket.svg";
import { ReactComponent as Add } from "../../assets/images/Icons/add.svg";
import { ReactComponent as Phone } from "../../assets/images/Icons/phone.svg";

export const identifiers = {
  auth_token: "auth_token",
  successdata: [
    {
      id: 1,
      year: "2022",
      title: " Best Designer",
      desc: "You can customize a template or make your own from scratch, with an immersive library at your disposal...",
    },
    {
      id: 2,
      year: "2021",
      title: "Best Designer",
      desc: "You can customize a template or make your own from scratch, with an immersive library at your disposal...",
    },
    {
      id: 3,
      year: "2020",
      title: "Best Designer",
      desc: "You can customize a template or make your own from scratch, with an immersive library at your disposal...",
    },
  ],
  blogData: [
    {
      id: 1,
      name: "John Smith",
      date: "02 June, 2022",
      desc: "Diversification of digital marketing stategies",
      bannerBG: Marketing,
    },
    {
      id: 2,
      name: "John Smith",
      date: "02 June, 2022",
      desc: "3 Simple Ways to Deal <br/> with Mistakes at Work",
      bannerBG: Mistake,
    },
    {
      id: 3,
      name: "John Smith",
      date: "02 June, 2022",
      desc: "We dare to choose a solution",
      bannerBG: solution,
    },
    {
      id: 4,
      name: "John Smith",
      date: "02 June, 2022",
      desc: "5 Tips to Better Focus in An Open-Plan Office",
      bannerBG: people,
    },
  ],
  feedBackData: [
    {
      id: 1,
      img: Client,
      details:
        " “Awesome App! Easy to use, it has a lot of options to design whatever you need, it is professional and fun. I was very successful creating my profile using designer which gave me unbelievable reach & appreciation.”",
      name: "Jason L. Guidry",
      postion: " CEO at designernews",
    },
    {
      id: 2,
      img: Client1,
      details:
        " “Awesome App! Easy to use, it has a lot of options to design whatever you need, it is professional and fun. I was very successful creating my profile using designer which gave me unbelievable reach & appreciation.”",
      name: "Jason L. Guidry",
      postion: " CEO at designernews",
    },
  ],

  ServiceData: [
    {
      id: 1,
      number: "01",
      title: "UI/UX Design",
      desc: "You can customize a template or make your own from scratch, with an immersive library at your disposal...",
    },
    {
      id: 2,
      number: "02",
      title: "User Research",
      desc: "You can customize a template or make your own from scratch, with an immersive library at your disposal...",
    },
    {
      id: 3,
      number: "03",
      title: "Ui Animation",
      desc: "You can customize a template or make your own from scratch, with an immersive library at your disposal...",
    },
    {
      id: 4,
      number: "04",
      title: "Product Design",
      desc: "You can customize a template or make your own from scratch, with an immersive library at your disposal...",
    },
  ],
  projectData: [
    {
      id: 1,
      image: FoodDelivery,
      name: "Branding for",
      details: "Food delivery company",
    },
    {
      id: 2,
      image: CarExport,
      name: "Social Media Marketing for",
      details: "Car Export Company",
    },
    {
      id: 3,
      image: Ecommerce,
      name: "Search Engine Marketing for",
      details: "Ecommerce website",
    },
    {
      id: 4,
      image: FoodDelivery,
      name: "Branding for",
      details: "Food delivery company",
    },
    {
      id: 5,
      image: CarExport,
      name: "Social Media Marketing for",
      details: "Car Export Company",
    },
    {
      id: 6,
      image: Ecommerce,
      name: "Search Engine Marketing for",
      details: "Ecommerce website",
    },
  ],
  clientData: [
    {
      id: 1,
      img: <Client12 />,
    },
    {
      id: 2,
      img: <Client2 />,
    },
    {
      id: 3,
      img: <Client3 />,
    },
    {
      id: 4,
      img: <Client12 />,
    },
    {
      id: 5,
      img: <Client2 />,
    },
    {
      id: 6,
      img: <Client3 />,
    },
  ],
  IntroData: [
    {
      id: 1,
      icon: <Figma />,
      name: "Figma",
      percentage: "90%",
    },
    {
      id: 2,
      icon: <Ae />,
      name: "After Effect",
      percentage: "80%",
    },
    {
      id: 3,
      icon: <Ps />,
      name: "Photoshop",
      percentage: "85%",
    },
    {
      id: 4,
      icon: <Xd />,
      name: "XD",
      percentage: "95%",
    },
    {
      id: 5,
      icon: <Ai />,
      name: "Illustrator",
      percentage: "90%",
    },
    {
      id: 6,
      icon: <Id />,
      name: "Indesign",
      percentage: "75%",
    },
  ],
  experienceData: [
    {
      id: 1,
      year: "2021 - Present",
      company: "Bauen Software Inc.",
      job: "Product Designer",
    },
    {
      id: 2,
      year: "2020 - 2021",
      company: "Bauen Software Inc.",
      job: "UX Designer",
    },
    {
      id: 3,
      year: "2018 - 2020",
      company: "Bauen Software Inc.",
      job: "UI Designer",
    },
    {
      id: 4,
      year: "2016 - 2018",
      company: "Bauen Software Inc.",
      job: "Web Designer",
    },
  ],
  educationData: [
    {
      id: 1,
      year: "2021 - Present",
      company: "Stanford Univercity",
      job: "Web Design Course",
    },
    {
      id: 2,
      year: "2020 - 2021",
      company: "Art Univercity of New York",
      job: "Art Director Course",
    },
    {
      id: 3,
      year: "2018 - 2020",
      company: "Amazon College",
      job: "IOS Development",
    },
    {
      id: 4,
      year: "2017 - 2018",
      company: "Univercity of Texas",
      job: "UX Expert",
    },
  ],
  menuData: [
    {
      id: 1,
      href: "/#home",
      sectionId: "home",
      name: "Home",
    },
    {
      id: 2,
      href: "/#about",
      sectionId: "about",
      name: "About",
    },
    {
      id: 3,
      href: "/#service",
      sectionId: "service",
      name: "Service",
    },
    {
      id: 4,
      href: "/#portfolio",
      sectionId: "portfolio",
      name: "Portfolio",
    },
    {
      id: 5,
      href: "/#blog",
      sectionId: "blog",
      name: "Blog",
    },
    {
      id: 6,
      href: "/#contact",
      sectionId: "contact",
      name: "Contact",
    },
  ],
  leftSideMenu: [
    { id: 1, href: "/#home", sectionId: "home", name: "Home", icon: <Home /> },
    {
      id: 2,
      href: "/#portfolio",
      sectionId: "portfolio",
      name: "Case Studies",
      icon: <Bucket />,
    },
    { id: 3, href: "/#blog", sectionId: "blog", name: "Blog", icon: <Add /> },
    {
      id: 4,
      href: "/#contact",
      sectionId: "contact",
      name: "Contact",
      icon: <Phone />,
    },
  ],
  ids: ["home", "about", "service", "portfolio", "blog", "contact"],
};

import React from "react";

// Mui
import { Box, Grid, Typography } from "@mui/material";

//COMPONENT
import { identifiers } from "../../../../../helpers/constants/identifier";

const Experience = () => {
  return (
    <>
      <Grid container className="experience-container">
        {identifiers?.experienceData?.map((item) => (
          <Grid item lg={6} key={item?.id} className="single-experience-box">
            <Box className="experience-list">
              <Box className="time">
                <Typography
                  className="year wow
                  animate__animated
                  animate__fadeInUp"
                >
                  {item?.year}
                </Typography>
                <Typography
                  className="company wow
                  animate__animated
                  animate__fadeInUp"
                >
                  {item?.company}
                </Typography>
              </Box>
              <Box
                className="job wow
                  animate__animated
                  animate__fadeInUp"
              >
                <Typography variant="h3">
                  <span>{item?.job}</span>
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Experience;
